import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import Img from 'gatsby-image'
import styled from '@emotion/styled'
import Layout from '../layouts/layout'
import Hero from '../components/Hero/Hero'
import HeadingWithSubHeading from '../components/HeadingWithSubHeading/HeadingWithSubHeading'
import Container from '../components/container'
import SEO from '../components/seo'
import HubspotForm from 'react-hubspot-form'
import ReactHtmlParser, {
    processNodes,
    convertNodeToElement,
    htmlparser2,
} from 'react-html-parser'

const PageTemplate = ({ title, content, image }) => (
    <section className="section">
        {image && (
            <Img
                fluid={image.localFile.childImageSharp.fluid}
                style={{ marginBottom: '2rem' }}
            />
        )}
        <div>
            {ReactHtmlParser(content, {
                transform: (node, index) => {
                    if (
                        node.attribs &&
                        node.attribs.hasOwnProperty('data-hubspot-form')
                    ) {
                        return (
                            <HubspotForm
                                className="hubspot"
                                portalId="3621575"
                                key={node.attribs['data-hubspot-form']}
                                formId={node.attribs['data-hubspot-form']}
                            />
                        )
                    }
                },
            })}
        </div>
    </section>
)

PageTemplate.propTypes = {
    title: PropTypes.string.isRequired,
    content: PropTypes.string,
}

const Page = ({ data }) => {
    const { wpPage: page } = data

    return (
        <Layout>
            <SEO title={page.title} />
            <Hero align="center">
                <HeadingWithSubHeading align="center">
                    <h2>{page.title}</h2>
                    {page.pageMeta.subheading && (
                        <p style={{ maxWidth: '750px' }}>
                            {page.pageMeta.subheading}
                        </p>
                    )}
                </HeadingWithSubHeading>
            </Hero>
            <Container>
                <PageTemplate title={page.title} content={page.content} />
            </Container>
        </Layout>
    )
}

Page.propTypes = {
    data: PropTypes.object.isRequired,
}

export default Page

export const pageQuery = graphql`
    query PageById($id: String!) {
        wpPage(id: { eq: $id }) {
            title
            content
            pageMeta {
                subheading
            }
        }
    }
`
